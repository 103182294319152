@include font-face('Sofia Pro', 'SofiaPro-Black', 900);
@include font-face('Sofia Pro', 'SofiaPro-BlackItalic', 900, italic);

@include font-face('Sofia Pro', 'SofiaPro-Black', 800);
@include font-face('Sofia Pro', 'SofiaPro-BlackItalic', 800, italic);

@include font-face('Sofia Pro', 'SofiaPro-Bold', 700);
@include font-face('Sofia Pro', 'SofiaPro-BoldItalic', 700, italic);

@include font-face('Sofia Pro', 'SofiaPro-SemiBold', 600);
@include font-face('Sofia Pro', 'SofiaPro-SemiBoldItalic', 600, italic);

@include font-face('Sofia Pro', 'SofiaPro-Medium', 500);
@include font-face('Sofia Pro', 'SofiaPro-MediumItalic', 500, italic);

@include font-face('Sofia Pro', 'SofiaPro-Regular');
@include font-face('Sofia Pro', 'SofiaPro-Italic', 400, italic);

@include font-face('Sofia Pro', 'SofiaPro-Light', 300);
@include font-face('Sofia Pro', 'SofiaPro-LightItalic', 300, italic);

@include font-face('Graphik', 'Graphik-Black', 900);
@include font-face('Graphik', 'Graphik-BlackItalic', 900, italic);

@include font-face('Graphik', 'Graphik-Black', 800);
@include font-face('Graphik', 'Graphik-BlackItalic', 800, italic);

@include font-face('Graphik', 'Graphik-Bold', 700);
@include font-face('Graphik', 'Graphik-BoldItalic', 700, italic);

@include font-face('Graphik', 'Graphik-Semibold', 600);
@include font-face('Graphik', 'Graphik-SemiboldItalic', 600, italic);

@include font-face('Graphik', 'Graphik-Medium', 500);
@include font-face('Graphik', 'Graphik-MediumItalic', 500, italic);

@include font-face('Graphik', 'Graphik-Regular');
@include font-face('Graphik', 'Graphik-RegularItalic', 400, italic);

@include font-face('Graphik', 'Graphik-Light', 300);
@include font-face('Graphik', 'Graphik-LightItalic', 300, italic);

@include font-face('Inter', 'Inter-Black', 900);
@include font-face('Inter', 'Inter-BlackItalic', 900, italic);

@include font-face('Inter', 'Inter-ExtraBold', 800);
@include font-face('Inter', 'Inter-ExtraBoldItalic', 800, italic);

@include font-face('Inter', 'Inter-Bold', 700);
@include font-face('Inter', 'Inter-BoldItalic', 700, italic);

@include font-face('Inter', 'Inter-SemiBold', 600);
@include font-face('Inter', 'Inter-SemiBoldItalic', 600, italic);

@include font-face('Inter', 'Inter-Medium', 500);
@include font-face('Inter', 'Inter-MediumItalic', 500, italic);

@include font-face('Inter', 'Inter-Regular');
@include font-face('Inter', 'Inter-RegularItalic', 400, italic);

@include font-face('Inter', 'Inter-Light', 300);
@include font-face('Inter', 'Inter-LightItalic', 300, italic);

@include font-face('Circular Std', 'CircularStd-Black', 900);
@include font-face('Circular Std', 'CircularStd-BlackItalic', 900, italic);

@include font-face('Circular Std', 'CircularStd-Bold', 700);
@include font-face('Circular Std', 'CircularStd-BoldItalic', 700, italic);

@include font-face('Circular Std', 'CircularStd-Medium', 500);
@include font-face('Circular Std', 'CircularStd-MediumItalic', 500, italic);

@include font-face('Circular Std', 'CircularStd-Book');
@include font-face('Circular Std', 'CircularStd-BookItalic', 400, italic);