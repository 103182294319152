// Color system

// scss-docs-start brand-color-variables
$royal-blue:#246CF9;
$royal-blue-2:#1E68F6;
$royal-blue-3:#0047D0;
$royal-blue-gradient: linear-gradient(265.56deg, $royal-blue -0.27%, $royal-blue-2 -0.26%, $royal-blue-3 98.59%);
$royal-blue-gradient-hover: linear-gradient(265.56deg, rgba($royal-blue,.5) -0.27%, rgba($royal-blue-2,.5) -0.26%, rgba($royal-blue-3,.5) 98.59%);
$baby-blue-light: #EDFDFF;
$ultramarine-blue: #706DF8;
$ultramine-blue-pale: #E9E9FF;
$ultramarine-blue-light: #F4F3FF;
$landing-page-details: #6F74B0;
// scss-docs-end brand-color-variables

// scss-docs-start black-color-variables
$white: #FFFFFF;
$black: #000000;
$grey-20: #E5E6ED;
$grey-40: #A5ADCF;
$grey-60: #5D6588;
$grey-80: #34384C;
$grey-100: #1E1F25;
$darker-grey: #141518; 
$dark-transparent: rgba(47, 50, 65, 0.5); 
$other-grey: #2E2E2E;
$other-grey-1:#101010;
$other-grey-2: #7D7D7D;
$other-grey-3:#22242C;
// scss-docs-end black-color-variables

// scss-docs-start accent-color-variables
$blue: #1E68F6;
$blue-link: #1A82FF;
$blue-pale: #BEE4FF;
$yellow: #F27F16;
$yellow-pale: #FFE4B1;
$green: #11CABE;
$green-light: #30E0A1;
$green-linear: linear-gradient(180deg, rgba(188, 251, 255, 0.4) 26.04%, rgba(0, 0, 0, 0) 100%);
$green-pale: #CCF0E6;
$red: #FA2256;
$other-red:#CC2229;
$red-linear: linear-gradient(180deg, rgba(252, 226, 237, 0.38) 10.42%, rgba(44, 44, 44, 0) 100%);
$red-pale: #FFD1CB;
$purple: #BD47FB;
$purple-nav: #7B61FF;
$purple-placeholder: #B9BDEA;
$gradient-pattern:linear-gradient(180deg, #0522AC 0%, rgba(52, 56, 76, 0) 100%);
$gradient-bg:radial-gradient(40.45% 40.45% at 50% 46.16%, rgba(1, 9, 80, 0.9) 3.12%, rgba(14, 17, 32, 0) 100%);
// scss-docs-end accent-color-variables

// scss-docs-start theme-color-variables
$primary: $royal-blue;
$secondary: $darker-grey;
$success: $green;
$info: $blue;
$warning: $yellow;
$danger: $red;
$light: $white;
$dark: $darker-grey;
// scss-docs-end theme-color-variables

// scss-docs-start shadow-color-variables
$shadow-color: rgba(159, 165, 174, 0.08);
$shadow-hover-color: rgba(0, 0, 0, 0.08);
$shadow-featured-hover-color: rgba(112, 109, 248, 0.1);
$shadow-blue-hover-color: rgba(112, 109, 248, 0.08);
// scss-docs-end shadow-color-variables


// Our custom color
$custom-colors: (
  'royal-blue':$royal-blue,
  'baby-blue-light':$baby-blue-light,
  'ultramarine-blue':$ultramarine-blue,
  'ultramine-blue-pale':$ultramine-blue-pale,
  'ultramarine-blue-light':$ultramarine-blue-light,
  'white':$white,
  'grey-20':$grey-20,
  'grey-40':$grey-40,
  'grey-60':$grey-60,
  'grey-80':$grey-80,
  'grey-100':$grey-100,
  'darker-grey':$darker-grey,
  'dark-transparent':$dark-transparent,
  'black':$black,
  'blue':$blue,
  'blue-pale':$blue-pale,
  'yellow':$yellow,
  'yellow-pale':$yellow-pale,
  'green':$green,
  'green-pale':$green-pale,
  'red':$red,
  'red-pale':$red-pale
);

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
);

$theme-colors: map-merge($theme-colors, $custom-colors);


// Minimum contrast
//
// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 1.5;


// Breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  xs-1: 375px,
  xs-2: 480px,
  sm: 576px,
  sm-2: 640px,
  md: 768px,
  lg: 992px,
  lg-2: 1064px,
  xl: 1200px,
  xl-2: 1400px,
  xxl: 1440px,
  xxl-2: 1441px,
);
// scss-docs-end grid-breakpoints


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1240px
);
// scss-docs-end container-max-widths


// Container padding

$container-padding-x: toRem(40);


// Body

// scss-docs-start body-elements
$body-bg: $darker-grey;
$body-color: $white;
// scss-docs-end body-elements


// Links

// scss-docs-start anchor-elements
$link-color: $primary;
$link-decoration: none;
$link-hover-decoration: null;
// scss-docs-end anchor-elements


// Paragraphs

// scss-docs-start paragraph-elements
$paragraph-margin-bottom: 0;
// scss-docs-end paragraph-elements


// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
$border-color: $grey-20;
$border-color-translucent: rgba($black, .175);
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius: 10px;
$border-radius-xs: 2px;
$border-radius-sm: 5px;
$border-radius-lg: 15px;
$border-radius-xl: 20px;
$border-radius-2xl: 25px;
// scss-docs-end border-radius-variables

// scss-docs-start box-shadow-variables
$box-shadow: 0px 12px 36px $shadow-color;
$box-shadow-hover: 0px 16px 28px $shadow-hover-color;
$box-shadow-featured-hover: 0px 16px 28px $shadow-featured-hover-color;
$box-shadow-blue-hover: 0px 16px 28px $shadow-blue-hover-color;
// scss-docs-end box-shadow-variables


// Typography

// scss-docs-start font-variables
$font-family-base: 'Sofia Pro',
  sans-serif;
$font-family-secondary: 'Graphik',
  sans-serif;
$font-family-inter: 'Inter',
  sans-serif;
$font-family-circularStd: 'Circular Std',
  sans-serif;

$font-size-base: toRem(16);
$font-mobile-size-base: toRem(14);
$font-size-xs: toRem(12);
$font-size-sm: toRem(14);
$font-mobile-size-sm: toRem(12);
$font-size-lg: toRem(18);
$font-mobile-size-lg: toRem(16);
$font-size-xl: toRem(20);

$font-display-size-xl: toRem(56);
$font-display-mobile-size-xl: toRem(39);
$font-display-size-lg: toRem(39);
$font-display-mobile-size-lg: toRem(28);
$font-display-size-md: toRem(25);
$font-display-mobile-size-md: toRem(20);
$font-display-size-sm: toRem(20);
$font-display-mobile-size-sm: toRem(18);

$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;

$line-height-base: 1.88;
$line-height-sm: 1.5;
$line-height-lg: 1.56;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$h1-font-size: toRem(61);
$h2-font-size: toRem(49);
$h3-font-size: toRem(39);
$h4-font-size: toRem(31);
$h5-font-size: toRem(25);
$h6-font-size: toRem(20);

$h1-mobile-font-size: toRem(34);
$h2-mobile-font-size: toRem(28);
$h3-mobile-font-size: toRem(22);
$h4-mobile-font-size: toRem(18);
$h5-mobile-font-size: toRem(16);
$h6-mobile-font-size: toRem(14);

$h1-line-height: 1.1;
$h2-line-height: 1.1;
$h3-line-height: 1.1;
$h4-line-height: 1.2;
$h5-line-height: 1.2;
$h6-line-height: 1.2;

$h1-mobile-line-height: 1.1;
$h2-mobile-line-height: 1.1;
$h3-mobile-line-height: 1.1;
$h4-mobile-line-height: 1.2;
$h5-mobile-line-height: 1.2;
$h6-mobile-line-height: 1.2;
// scss-docs-end font-sizes

// scss-docs-start headings-variables
$headings-margin-bottom: 0;
$headings-font-weight: 700;
$headings-color: $white;
// scss-docs-end headings-variables


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y: toRem(16);
$input-btn-padding-x: toRem(32);
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-sm;

$input-btn-padding-y-sm: toRem(12);
$input-btn-padding-x-sm: toRem(32);
$input-btn-font-size-sm: $font-size-sm;

$input-btn-padding-y-lg: toRem(16);
$input-btn-padding-x-lg: toRem(32);
$input-btn-font-size-lg: $font-size-lg ;
// scss-docs-end input-btn-variables


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-white-space: nowrap; // Set to `nowrap` to prevent text wrapping or set to `null` to default

$btn-font-weight: $font-weight-bold;
$btn-box-shadow: null;
$btn-focus-box-shadow: null;
$btn-active-box-shadow: null;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius;
$btn-border-radius-sm: $border-radius;
$btn-border-radius-lg: $border-radius;
// scss-docs-end btn-variables


// Forms

// scss-docs-start form-text-variables
$form-text-margin-top: toRem(6);
$form-text-font-size: $font-size-sm;
$form-text-font-weight: $font-weight-base;
$form-text-color: $grey-60;
// scss-docs-end form-text-variables

// scss-docs-start form-label-variables
$form-label-margin-bottom: toRem(12);
$form-label-font-size: $font-size-base;
$form-label-font-weight: $font-weight-bold;
$form-label-color: $black;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
$input-padding-y: toRem(13);
$input-padding-x: toRem(16);
$input-font-size: $font-size-base;
$input-font-weight: $font-weight-semibold;
$input-line-height: $line-height-sm;

$input-padding-y-sm: toRem(16);
$input-padding-x-sm: toRem(16);
$input-font-size-sm: $font-size-sm;

$input-padding-y-lg: toRem(16);
$input-padding-x-lg: toRem(16);
$input-font-size-lg: $font-size-lg;

$input-bg: $body-bg;

$input-color: $black;
$input-border-color: $grey-20;
$input-border-width: 1px;
$input-box-shadow: null;

$input-border-radius: $border-radius;
$input-border-radius-sm: $border-radius;
$input-border-radius-lg: $border-radius;

$input-focus-bg: $input-bg;
$input-focus-border-color: $ultramarine-blue;
$input-focus-color: $input-color;
$input-focus-box-shadow: unset;

$input-placeholder-color: $grey-40;
$input-plaintext-color: $body-color;

$input-height: 56px;
$input-height-sm: 52px;
$input-height-lg: 60px;
// scss-docs-end form-input-variables

// scss-docs-start form-check-variables
$form-check-input-width: 16px;
$form-check-padding-start: calc($form-check-input-width + 8px);
$form-check-margin-bottom: 0;
$form-check-label-cursor: null;

$form-check-input-bg: $input-bg;
$form-check-input-border: 1px solid $grey-40;
$form-check-input-border-radius: 2px;
$form-check-radio-border-radius: 50%;
$form-check-input-focus-border: $input-focus-border-color;
$form-check-input-focus-box-shadow: unset;

$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$primary}'/></svg>") !default;
// scss-docs-end form-check-variables

// Badges

// scss-docs-start badge-variables
$badge-font-size: toRem(12);
$badge-font-weight: $font-weight-bold;
$badge-color: $white;
$badge-padding-y: toRem(6);
$badge-padding-x: toRem(8);
$badge-border-radius: $border-radius-sm;
// scss-docs-end badge-variables