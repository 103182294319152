*{  
  font-smooth: always;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important; 
  scroll-behavior: smooth !important;
}

a {
  font-weight: $font-weight-bold;
}

h1 {
  @extend %heading;
  @include heading-size($h1-font-size, $h1-mobile-font-size, $h1-line-height, $h1-mobile-line-height);
}

h2 {
  @extend %heading;
  @include heading-size($h2-font-size, $h2-mobile-font-size, $h2-line-height, $h2-mobile-line-height);

}

h3 {
  @extend %heading;
  @include heading-size($h3-font-size, $h3-mobile-font-size, $h3-line-height, $h3-mobile-line-height);

}

h4 {
  @extend %heading;
  @include heading-size($h4-font-size, $h4-mobile-font-size, $h4-line-height, $h4-mobile-line-height);

}

h5 {
  @extend %heading;
  @include heading-size($h5-font-size, $h5-mobile-font-size, $h5-line-height, $h5-mobile-line-height);

}

h6 {
  @extend %heading;
  @include heading-size($h6-font-size, $h6-mobile-font-size, $h6-line-height, $h6-mobile-line-height);
}

.container{
  @include media-breakpoint-down(md) {
    padding: 0 toRem(24);
  }
  @include media-breakpoint-down(xs-1) {
    padding: 0 toRem(16);
  }
  &.container--dashboard{
    max-width: toRem(1440);
    padding: 0 toRem(44);
    @include media-breakpoint-down(xl) {
      padding: 0 toRem(40);
    }
    @include media-breakpoint-down(lg) {
      padding: 0 toRem(32);
    }
    @include media-breakpoint-down(md) {
      padding: 0 toRem(24);
    }
    @include media-breakpoint-down(sm) {
      padding: 0 toRem(16);
    }
  }
  &.container--dashboard-nav{
    max-width: 100% !important;
    padding: 0 toRem(44);
    @include media-breakpoint-down(xl) {
      padding: 0 toRem(40);
    }
    @include media-breakpoint-down(lg) {
      padding: 0 toRem(32);
    }
    @include media-breakpoint-down(md) {
      padding: 0 toRem(24);
    }
    @include media-breakpoint-down(sm) {
      padding: 0 toRem(16);
    }
  }
}